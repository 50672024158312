var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑摄像头"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"摄像头名称"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {
              rules: [
                { required: true, message: '请选择摄像头' }
              ]
            }]),expression:"['code', {\n              rules: [\n                { required: true, message: '请选择摄像头' }\n              ]\n            }]"}],attrs:{"placeholder":"请选择摄像头","show-search":"","filter-option":_vm.filterOption,"option-filter-prop":"children"},on:{"load":_vm.loadingCameraOptions,"change":_vm.changeCamera}},_vm._l((_vm.cameraOptions),function(camera){return _c('a-select-option',{key:camera.code,attrs:{"value":camera.code}},[_vm._v(" "+_vm._s(camera.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"通道号"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['no', {
              rules: [
                { required: true, message: '请输入通道号' } ]
            }]),expression:"['no', {\n              rules: [\n                { required: true, message: '请输入通道号' },\n              ]\n            }]"}],staticStyle:{"width":"100%"},attrs:{"min":1,"max":10000000,"step":1,"precision":0}})],1),_c('a-form-item',{attrs:{"label":"厅房"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['hall_id', {
              rules: [
                { required: true, message: '请选择厅房' }
              ]
            }]),expression:"['hall_id', {\n              rules: [\n                { required: true, message: '请选择厅房' }\n              ]\n            }]"}],attrs:{"placeholder":"请选择厅房","show-search":"","filter-option":_vm.filterOption,"option-filter-prop":"children"},on:{"load":_vm.loadingHallOptions}},_vm._l((_vm.hallOptions),function(hall){return _c('a-select-option',{key:hall.id,attrs:{"value":hall.id}},[_vm._v(" "+_vm._s(hall.name)+" ")])}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }